.outline {
  border: 1px solid #ddd;
  border-radius: 3px;
}
textarea.processed.md-input {
  padding: 10px;
}
textarea {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	width: 100%;
}

/* http://johnsardine.com/freebies/dl-html-css/simple-little-tab/ */
.marked > table a:link,
.markdown > table a:link {
	color: #666;
	font-weight: bold;
	text-decoration:none;
}
.marked > table a:visited,
.markdown > table a:visited {
	color: #999999;
	font-weight:bold;
	text-decoration:none;
}
.marked > table a:active,
.marked > table a:hover,
.markdown > table a:active,
.markdown > table a:hover {
	color: #bd5a35;
	text-decoration:underline;
}
.marked > table,
.markdown > table {
	font-family:Arial, Helvetica, sans-serif;
	color:#606060;
	font-size:15px;
	background:#eaeaea;
	margin:10px;
	border:#ccc 1px solid;

	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	border-radius:3px;
}
.marked > table th,
.markdown > table th {
	padding:8px 15px;
	border-bottom:1px solid #e0e0e0;
	background: #f6f6f6;
  color:#333;
}
.marked > table th:first-child,
.markdown > table th:first-child {
	text-align: left;
	padding-left:10px;
}
.marked > table tr:first-child th:first-child,
.markdown > table tr:first-child th:first-child {
	-moz-border-radius-topleft:3px;
	-webkit-border-top-left-radius:3px;
	border-top-left-radius:3px;
}
.marked > table tr:first-child th:last-child,
.markdown > table tr:first-child th:last-child {
	-moz-border-radius-topright:3px;
	-webkit-border-top-right-radius:3px;
	border-top-right-radius:3px;
}
.marked > table tr,
.markdown > table tr {
	text-align: center;
	padding-left:10px;
}
.marked > table td:first-child,
.markdown > table td:first-child {
	text-align: left;
	padding-left:10px;
	border-left: 0;
}
.marked > table td,
.markdown > table td {
	padding:8px;
	border-top: 1px solid #ffffff;
	border-bottom:1px solid #e0e0e0;
	border-left: 1px solid #e0e0e0;

	background: #fff;
}

.marked > table tr:nth-child(2n) td,
.markdown > table tr:nth-child(2n) td {
	background: #f8f8f8;
}
.marked > table tr:last-child td,
.markdown > table tr:last-child td {
	border-bottom:0;
}
.marked > table tr:last-child td:first-child,
.markdown > table tr:last-child td:first-child {
	-moz-border-radius-bottomleft:3px;
	-webkit-border-bottom-left-radius:3px;
	border-bottom-left-radius:3px;
}
.marked > table tr:last-child td:last-child,
.markdown > table tr:last-child td:last-child {
	-moz-border-radius-bottomright:3px;
	-webkit-border-bottom-right-radius:3px;
	border-bottom-right-radius:3px;
}
.marked > table tr:hover td,
.markdown > table tr:hover td {
	background: #f4f4f4;
	background: -webkit-gradient(linear, left top, left bottom, from(#f4f4f4), to(#f2f2f2));
	background: -moz-linear-gradient(top,  #f4f4f4,  #f2f2f2);
}
